export default defineNuxtPlugin(async (nuxtApp) => {
  const { $axios, $reportError } = useNuxtApp();
  const getClientIP = async (): Promise<string> => {
    try {
      const res = await fetch("https://api64.ipify.org?format=json");
      const data = await res.json();
      return data.ip;
    } catch (error: any) {
      $reportError("Error Gettin Client IP", error);
      return "0.0.0.0";
    }
  };

  const clientIP = await getClientIP();

  $axios.interceptors.request.use((config: any) => {
    config.headers = {
      ...config.headers,
      "X-Client-IP": clientIP,
    };
    return config;
  }, null);
});
