import { Track } from "livekit-client";
import type { useMiddleware } from "~/store/middleware";

// Array of special subdomains that should not be treated as user accounts
export const SPECIAL_SUBDOMAINS = ["app", "uat"];

// Function to check if a subdomain is a special subdomain
export function isSpecialSubdomain(subdomain: string): boolean {
  return SPECIAL_SUBDOMAINS.includes(subdomain);
}

export function getSourceIcon(source: Track.Source, enabled: boolean) {
  switch (source) {
    case Track.Source.Microphone:
      return enabled
        ? "system-uicons:microphone"
        : "system-uicons:microphone-disabled";
    case Track.Source.Camera:
      return enabled
        ? "system-uicons:camera-alt"
        : "system-uicons:camera-noflash-alt";
    case Track.Source.ScreenShare:
      return enabled ? "tabler:screen-share" : "tabler:screen-share-off";
  }
}

export function formatPhone(phone: string) {
  let cleaned = ("" + phone).replace(/\D/g, "");

  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

interface Timer {
  string: string;
  minutes: number;
  seconds: number;
  totalSeconds: number;
}

export function useCountDown(initialMinutes: number, onComplete?: () => void) {
  const min = ref(initialMinutes);
  const isRunning = ref(false);
  let intervalId: NodeJS.Timeout | null = null;

  const formatTimer = (value: number): Timer => {
    const minutes = Math.floor(value / 60);
    const seconds = value - minutes * 60;
    const totalSeconds = value;
    const string = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return { string, minutes, seconds, totalSeconds };
  };

  const timer = ref(formatTimer(min.value));

  const start = () => {
    if (!isRunning.value) {
      isRunning.value = true;
      intervalId = setInterval(() => {
        min.value--;
        timer.value = formatTimer(min.value);
        if (min.value === 0) {
          stop();
          if (onComplete) {
            onComplete();
          }
        }
      }, 1000);
    }
  };

  const pause = () => {
    if (isRunning.value) {
      isRunning.value = false;
      clearInterval(intervalId!);
      intervalId = null;
    }
  };

  const stop = () => {
    if (isRunning.value) {
      pause();
    }
    min.value = initialMinutes;
    timer.value = formatTimer(min.value);
  };

  const restart = () => {
    stop();
    start();
  };

  watch(
    () => min.value,
    (value) => {
      if (value === 0) {
        stop();
        if (onComplete) {
          onComplete();
        }
      }
    },
  );

  return {
    timer,
    isRunning,
    start,
    pause,
    stop,
    restart,
  };
}

export async function checkSubdomainExist(
  middleware: ReturnType<typeof useMiddleware>,
  subdomain: string,
) {
  middleware.subdomainData = subdomain;
  if (!middleware.subdomain || middleware.subdomainIsStale) {
    await middleware.subdomainRefetch();
  }
  if (!middleware.subdomain) {
    throw new Error("Subdomain does not exist in this tenant");
  }
}

function getFilenameFromUrl(url: string) {
  const urlObj = new URL(url);
  return urlObj.pathname.split("/").pop() ?? "";
}

export async function downloadFile(
  state: string,
  recordId: number,
  id: number,
) {
  const { $axios } = useNuxtApp();
  const responses = await $axios.get(
    `/v1/${state}_records/${recordId}/recordings/${id}/download`,
  );
  const presignedUrl = responses.data.presigned_url;

  const filename = getFilenameFromUrl(presignedUrl);
  const link = document.createElement("a");
  link.href = presignedUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
