import { useMutation, useQuery } from "@tanstack/vue-query";

export const useMiddleware = defineStore("middleware", () => {
  const { $axios, $reportError } = useNuxtApp();

  const staleTime = 1000 * 60 * 5;
  const subdomainData = ref<string>("");

  const {
    data: subdomain,
    isStale: subdomainIsStale,
    isSuccess: isSubdomain,
    refetch: subdomainRefetch,
  } = useQuery({
    queryKey: ["subdomain", subdomainData],
    queryFn: async () =>
      await $axios
        .get(`/v1/accounts/${subdomainData.value}`)
        .then((res) => res.data)
        .catch((error) => $reportError("Middleware store", error)),
    staleTime,
    enabled: false,
  });

  const {
    data: account,
    isStale: accountDataIsStale,
    refetch: accountRefetch,
    isSuccess: isAccountSuccess,
    isError: isAccountError,
  } = useQuery({
    queryKey: ["account"],
    queryFn: async () =>
      await $axios
        .get(`/v1/account`)
        .then((res) => res.data)
        .catch((error) => {
          $reportError("Middleware store", error);
          return "";
        }),
    staleTime,
    enabled: false,
  });

  const { mutateAsync: session } = useMutation({
    mutationFn: async (data: any) =>
      await $axios.get(`/v1/sessions/${data}`).then((res) => res.data),
    onSuccess: (data) => data,
    onError: (error) => $reportError("Middleware store", error),
  });

  const { mutateAsync: checkToken, isSuccess: isTokenSuccess } = useMutation({
    mutationFn: async (data: { token: string; email: string }) => {
      await $axios.get("/sanctum/csrf-cookie");
      return await $axios
        .post("/reset-password/valid-token", {
          token: data.token,
          email: data.email,
        })
        .then((res) => res.data);
    },
    onSuccess: (data) => data,
    onError: (error: any) => $reportError("Middleware store", error),
  });

  return {
    checkToken,
    isTokenSuccess,
    session,
    account,
    accountDataIsStale,
    accountRefetch,
    isAccountSuccess,
    isAccountError,
    subdomain,
    subdomainData,
    subdomainIsStale,
    subdomainRefetch,
    isSubdomain,
  };
});
